import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2560.000000 1565.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1565.000000) scale(0.100000,-0.100000)">

<path d="M22037 12120 c-21 -4 -52 -20 -70 -37 -18 -16 -42 -38 -54 -49 -42
-37 -54 -109 -32 -188 23 -82 84 -129 184 -139 381 -42 626 -108 885 -239 103
-52 163 -87 230 -133 24 -16 51 -35 62 -41 10 -6 29 -21 42 -33 12 -11 27 -21
32 -21 6 0 25 -16 44 -35 19 -19 38 -35 42 -35 15 0 298 -286 298 -301 0 -8
21 -36 45 -64 42 -46 86 -112 127 -190 9 -17 23 -37 32 -44 9 -8 16 -22 16
-32 0 -10 4 -20 9 -23 12 -8 101 -189 101 -207 0 -8 4 -19 9 -25 9 -9 67 -178
94 -274 29 -104 66 -360 65 -445 0 -11 0 -33 2 -50 1 -16 1 -75 0 -131 -1 -88
2 -107 24 -158 14 -32 26 -60 26 -62 0 -9 -439 -3 -445 5 -3 5 15 26 39 46 52
42 62 66 71 164 6 74 -8 293 -25 386 -33 173 -59 272 -106 393 -33 85 -103
230 -115 237 -5 4 -9 14 -9 24 0 10 -7 25 -17 32 -9 8 -22 28 -29 44 -7 17
-30 51 -52 75 -22 25 -41 53 -43 62 -4 18 -130 161 -191 218 -98 90 -145 132
-168 147 -14 9 -29 22 -35 28 -9 10 -101 72 -179 119 -60 38 -197 106 -211
106 -8 0 -15 3 -15 8 0 7 -32 20 -140 53 -36 12 -72 23 -80 26 -24 9 -31 11
-95 24 -200 38 -399 57 -426 41 -10 -7 -24 -12 -31 -12 -24 0 -77 -55 -97
-100 -11 -25 -21 -65 -21 -89 0 -51 42 -149 68 -158 9 -3 24 -12 32 -19 30
-25 100 -41 186 -43 48 -1 95 -6 105 -12 10 -5 33 -9 53 -9 19 0 38 -4 41 -10
3 -5 17 -10 30 -10 45 0 292 -105 380 -161 17 -10 39 -23 50 -28 12 -5 27 -16
34 -25 7 -9 19 -16 25 -16 7 0 23 -11 36 -25 13 -14 28 -25 33 -25 6 0 40 -28
76 -61 36 -34 79 -73 95 -88 15 -14 49 -55 74 -91 25 -36 59 -80 74 -98 15
-18 28 -39 28 -46 0 -8 6 -18 13 -25 8 -6 32 -48 54 -93 121 -247 160 -400
168 -653 3 -88 8 -174 10 -191 8 -48 33 -91 66 -114 16 -12 29 -25 29 -29 0
-4 -54 -11 -120 -15 -192 -11 -357 -33 -470 -63 -30 -8 -68 -18 -85 -23 -124
-32 -304 -132 -393 -217 -21 -20 -30 -16 -82 30 -65 58 -170 118 -284 163 -53
21 -96 41 -96 45 0 4 26 15 57 25 82 26 185 119 227 206 30 62 52 188 36 216
-5 9 -13 38 -19 64 -16 69 -68 145 -132 193 -73 55 -116 71 -218 81 -65 7
-169 -15 -202 -43 -8 -7 -22 -13 -29 -13 -15 0 -100 -78 -100 -92 0 -4 -13
-26 -28 -50 -43 -64 -56 -125 -49 -225 4 -48 11 -91 17 -97 5 -5 10 -17 10
-26 0 -9 28 -47 63 -84 34 -38 68 -77 75 -88 13 -17 -16 -18 -955 -19 -532 -1
-1081 0 -1218 1 -277 2 -315 -3 -371 -52 -68 -60 -64 3 -64 -960 0 -1006 -2
-1034 -81 -1189 -54 -109 -155 -183 -295 -219 -68 -18 -111 -20 -356 -20 -220
0 -294 3 -351 16 -191 43 -308 162 -357 365 -19 81 -20 117 -20 969 0 546 -4
901 -10 928 -15 62 -49 108 -100 134 -42 21 -59 23 -245 26 -110 2 -223 1
-251 -3 -96 -12 -164 -79 -179 -177 -3 -21 -11 -38 -16 -38 -6 0 -43 20 -82
43 -161 98 -378 162 -626 187 -220 21 -868 4 -1091 -29 -303 -46 -551 -154
-681 -297 -144 -161 -208 -385 -179 -631 11 -87 63 -244 87 -262 5 -3 21 -25
36 -47 65 -96 211 -209 357 -277 272 -126 451 -178 1025 -296 417 -87 587
-143 691 -227 59 -47 78 -93 71 -167 -8 -84 -43 -132 -130 -176 l-66 -34 -193
-7 c-106 -5 -339 -7 -518 -5 -344 2 -392 8 -495 56 -61 29 -128 94 -170 168
-50 85 -97 135 -150 161 -40 19 -62 21 -232 22 -113 1 -198 -3 -214 -10 -40
-16 -95 -68 -113 -106 -18 -37 -21 -155 -7 -217 5 -21 5 -42 2 -48 -5 -8 -208
-10 -727 -9 l-719 3 -5 1131 c-4 986 -7 1133 -20 1149 -8 10 -14 21 -14 24 7
29 -47 83 -84 86 -10 1 -27 7 -40 14 -18 9 -80 11 -255 7 -270 -6 -220 -6
-522 0 -226 5 -243 4 -286 -15 -51 -23 -97 -75 -104 -118 -2 -15 -7 -426 -10
-913 -3 -515 -9 -910 -15 -946 -45 -273 -192 -408 -474 -436 -172 -16 -533 -4
-621 21 -185 52 -284 160 -334 363 -21 85 -21 103 -21 975 0 697 -3 897 -13
931 -17 56 -56 104 -104 128 -37 18 -88 19 -1582 22 -1016 2 -1563 -1 -1600
-8 -86 -15 -176 -66 -232 -130 -47 -53 -78 -99 -334 -495 -283 -437 -395 -605
-403 -605 -8 0 -40 45 -147 215 -18 28 -70 109 -117 180 -47 72 -127 195 -178
275 -272 423 -297 457 -381 507 -93 55 -144 63 -421 63 l-247 0 -36 -31 -35
-31 -67 22 c-95 31 -249 67 -373 87 -85 14 -167 18 -430 18 -342 -1 -406 -6
-625 -56 -230 -53 -445 -154 -596 -280 -26 -22 -49 -39 -52 -39 -3 0 -7 37 -8
83 -5 100 -27 149 -88 192 l-43 30 -267 0 c-266 0 -268 0 -303 -24 -19 -13
-46 -40 -59 -59 l-24 -36 -6 -955 c-6 -1038 -4 -996 -62 -1120 -26 -56 -98
-129 -158 -158 -95 -47 -160 -57 -379 -58 -113 0 -231 3 -262 8 l-58 7 0 -306
0 -306 323 5 c333 5 385 10 560 57 153 40 242 80 376 167 85 55 205 168 251
236 14 20 30 37 36 37 7 0 30 -24 51 -53 159 -208 418 -367 730 -446 221 -57
297 -64 653 -65 355 -1 430 6 645 56 463 108 785 373 937 770 80 208 108 360
124 683 l7 139 23 -30 c13 -17 89 -131 169 -255 l145 -224 0 -458 c0 -390 2
-463 15 -495 22 -50 52 -81 103 -103 39 -17 71 -19 280 -19 217 0 239 2 278
21 47 22 84 66 102 118 9 24 12 170 12 490 l1 456 82 126 c45 70 145 224 222
343 77 119 160 247 185 285 25 39 83 130 129 203 102 161 112 173 123 156 4
-7 8 -471 8 -1031 0 -870 2 -1023 15 -1053 16 -40 64 -84 109 -101 44 -16 465
-17 520 -1 54 17 111 76 125 132 7 30 11 196 11 495 l0 451 755 2 c415 1 765
2 777 2 21 1 23 -6 35 -94 22 -170 58 -301 114 -413 125 -248 330 -420 615
-515 82 -27 114 -35 259 -63 193 -36 835 -36 1030 1 175 33 300 72 418 131
112 56 186 109 272 196 54 54 79 73 84 64 5 -7 9 -69 10 -138 2 -135 9 -159
58 -205 64 -60 2 -58 1344 -58 1336 0 1285 -2 1339 56 27 28 51 30 109 10 36
-13 69 -22 216 -56 184 -43 1066 -37 1382 9 138 20 289 63 408 116 82 36 173
102 246 176 l66 69 64 -62 c150 -143 364 -251 588 -294 282 -56 907 -60 1176
-8 169 33 259 63 390 131 97 50 147 85 230 165 40 37 75 65 78 61 4 -3 7 -55
7 -115 0 -88 4 -116 20 -147 24 -48 70 -87 118 -101 26 -8 345 -10 1067 -8
l1030 4 105 26 c181 45 337 112 440 190 28 21 55 38 61 38 5 0 44 -23 86 -52
142 -95 334 -170 538 -209 99 -20 1150 -20 1280 -1 367 54 580 138 733 289 32
32 72 80 90 107 l32 49 0 382 0 381 -43 59 c-148 202 -436 351 -912 470 -133
33 -522 117 -630 136 -42 7 -113 25 -280 68 -58 15 -171 64 -221 96 -89 57
-122 128 -100 215 23 90 70 132 182 167 53 16 102 18 499 18 386 0 448 -2 502
-17 133 -37 205 -92 274 -210 56 -95 82 -123 147 -155 53 -26 66 -28 214 -31
218 -6 285 14 342 102 24 37 26 49 26 148 0 85 -4 119 -21 165 -24 64 -73 144
-128 212 -106 129 -394 291 -598 336 -18 3 -33 10 -33 14 0 4 16 22 35 41 19
19 35 39 35 46 0 6 6 17 14 23 43 36 20 529 -35 766 -29 123 -85 320 -107 375
-6 14 -20 50 -32 80 -30 76 -91 199 -101 206 -5 3 -9 12 -9 21 0 8 -9 26 -20
40 -11 14 -20 33 -20 43 0 9 -9 25 -20 35 -11 10 -20 24 -20 30 0 7 -13 29
-30 50 -16 21 -30 42 -30 47 0 5 -18 30 -39 56 -22 26 -46 60 -55 76 -15 30
-237 281 -270 306 -11 8 -49 43 -85 78 -35 34 -68 62 -72 62 -5 0 -25 16 -45
35 -19 19 -40 35 -44 35 -5 0 -18 10 -29 21 -11 12 -29 27 -39 33 -21 12 -140
90 -158 103 -48 36 -283 153 -398 198 -226 88 -438 145 -611 165 -55 6 -128
15 -161 20 -68 11 -82 11 -137 0z m-18561 -3515 c92 -24 185 -65 248 -110 16
-11 69 -58 118 -105 142 -134 233 -289 280 -475 11 -44 25 -95 30 -114 15 -49
14 -354 -1 -421 -32 -145 -47 -192 -92 -285 -65 -136 -114 -208 -195 -286 -80
-76 -132 -108 -226 -139 -89 -28 -110 -25 -120 20 -4 19 -12 53 -17 75 -5 22
-21 94 -36 160 -51 227 -57 254 -76 330 -6 22 -14 58 -18 80 -5 22 -14 68 -22
103 -12 56 -11 65 4 91 27 46 22 95 -14 136 -40 45 -72 46 -116 3 -40 -39 -45
-92 -12 -144 11 -19 17 -39 13 -46 -4 -6 -11 -28 -15 -47 -5 -20 -12 -54 -18
-76 -14 -62 -62 -275 -71 -320 -4 -22 -15 -67 -23 -100 -31 -122 -47 -195 -47
-214 0 -39 -22 -71 -49 -71 -57 0 -176 47 -235 93 -173 134 -322 406 -358 652
-15 101 -14 296 1 399 20 137 87 311 170 436 208 317 558 463 897 375z m12732
-49 c86 -25 161 -66 208 -115 24 -25 44 -49 44 -53 0 -18 93 -149 118 -167 66
-46 96 -54 212 -60 l115 -6 3 -262 c3 -295 7 -281 -70 -240 -61 32 -258 111
-353 141 -77 24 -328 89 -410 106 -22 4 -119 24 -215 44 -96 20 -209 43 -250
51 -41 8 -92 20 -112 25 -21 6 -55 14 -75 20 -226 59 -355 127 -398 210 -17
34 -21 75 -12 131 13 77 71 133 182 174 47 18 193 23 565 20 317 -3 405 -7
448 -19z m5539 -5 c29 -13 69 -41 88 -62 56 -62 68 -101 69 -214 1 -85 -3
-108 -23 -153 -29 -64 -88 -117 -152 -137 -37 -10 -176 -15 -631 -21 -544 -6
-585 -6 -601 10 -15 16 -17 46 -17 300 0 211 3 285 12 294 10 10 144 12 608
10 l595 -3 52 -24z m-12571 -2 c12 -9 14 -73 14 -368 0 -196 -4 -360 -8 -366
-4 -7 -273 -9 -779 -7 l-773 3 0 369 0 370 228 2 c786 10 1304 8 1318 -3z
m-6486 -74 c0 -2 -25 -31 -55 -62 -31 -32 -77 -88 -102 -125 -26 -38 -48 -66
-51 -63 -5 5 42 87 77 134 48 64 131 137 131 116z m19885 -730 c105 -95 352
-212 605 -288 167 -51 247 -69 725 -169 322 -67 515 -129 599 -194 70 -53 89
-87 89 -155 -1 -105 -63 -178 -183 -216 -49 -16 -105 -18 -555 -18 -497 0
-500 0 -575 23 -126 40 -191 94 -259 213 -40 72 -92 123 -152 151 -45 20 -49
24 -55 67 -30 220 -153 413 -341 534 -24 15 -43 31 -43 36 0 5 16 23 35 40 41
35 45 34 110 -24z m-778 -339 c151 -40 233 -163 233 -347 0 -47 -7 -108 -15
-136 -36 -120 -126 -201 -249 -222 -92 -16 -1258 -15 -1274 1 -9 9 -12 101
-12 353 0 252 3 344 12 353 19 19 1234 17 1305 -2z m-18493 -54 c3 -20 10 -59
15 -87 15 -87 -39 -142 -74 -74 -15 27 -15 36 0 102 9 40 19 83 21 95 7 34 30
12 38 -36z m60 -279 c6 -18 4 -22 -8 -17 -18 7 -27 29 -19 50 7 18 15 8 27
-33z m-140 0 c-15 -24 -34 -28 -34 -6 0 26 21 46 34 33 7 -7 7 -16 0 -27z m68
-65 c25 -20 88 -106 88 -121 0 -15 -41 -56 -80 -80 -24 -16 -27 -15 -57 13
-18 17 -36 30 -41 30 -5 0 -15 7 -22 15 -10 11 -6 22 19 57 17 24 39 55 48 70
20 32 23 33 45 16z m-115 -230 c13 -12 23 -27 23 -33 0 -6 -18 -29 -40 -50
-29 -28 -43 -36 -51 -28 -8 8 -8 16 0 31 6 11 11 30 11 42 0 23 17 60 27 60 4
0 17 -10 30 -22z m261 -18 c15 -49 16 -90 1 -90 -14 0 -89 62 -89 75 0 11 51
54 65 55 6 0 16 -18 23 -40z m-129 -85 c29 -14 45 -38 34 -49 -9 -8 -118 -8
-126 1 -8 7 41 63 55 63 5 0 22 -7 37 -15z"/>
<path d="M3095 8481 c-291 -84 -502 -333 -575 -681 -18 -87 -21 -125 -17 -239
6 -166 17 -227 62 -344 62 -159 163 -298 284 -391 97 -74 145 -92 156 -57 9
30 -2 45 -63 81 -78 46 -166 132 -219 214 -71 108 -133 253 -133 311 0 17 -5
36 -11 42 -14 14 -15 333 -1 359 5 11 17 51 27 89 16 68 78 200 119 259 90
124 239 239 352 271 33 9 73 21 90 26 87 26 289 -1 389 -53 148 -76 294 -226
351 -362 9 -23 23 -56 31 -74 14 -32 27 -82 53 -202 13 -62 9 -249 -8 -320
-49 -210 -94 -300 -217 -438 -45 -50 -133 -120 -183 -146 -27 -14 -33 -22 -30
-44 2 -18 10 -28 23 -30 47 -7 243 158 315 265 63 93 110 204 148 348 21 80
29 218 18 333 -5 61 -14 112 -18 112 -4 0 -8 12 -8 28 0 55 -75 229 -141 327
-35 52 -145 166 -199 207 -60 45 -162 94 -232 111 -90 23 -280 21 -363 -2z"/>
<path d="M3120 8281 c-163 -53 -316 -200 -387 -372 -61 -149 -79 -295 -55
-439 18 -106 24 -130 33 -136 5 -3 9 -13 9 -23 0 -18 56 -133 84 -172 44 -63
168 -177 202 -185 65 -17 73 33 12 74 -38 25 -119 100 -143 133 -87 120 -145
295 -145 439 0 161 84 366 195 474 64 64 71 68 135 102 70 37 119 47 220 47
153 1 232 -34 351 -152 115 -114 153 -193 188 -387 14 -79 14 -112 -4 -214
-19 -109 -64 -220 -118 -291 -43 -57 -140 -149 -157 -149 -16 0 -41 -46 -34
-64 25 -64 213 94 290 245 67 129 77 169 82 346 4 178 -2 225 -48 340 -79 196
-216 330 -395 384 -80 25 -238 25 -315 0z"/>
<path d="M3159 8086 c-20 -6 -42 -16 -50 -21 -8 -6 -27 -17 -43 -25 -67 -34
-149 -138 -185 -235 -57 -153 -47 -344 26 -489 27 -54 116 -154 148 -166 23
-9 45 5 45 30 0 9 -25 41 -55 71 -187 182 -183 511 8 686 31 28 73 55 98 63
24 7 48 17 54 22 13 12 113 10 154 -3 140 -45 232 -141 277 -288 17 -55 18
-220 3 -270 -25 -78 -74 -162 -125 -212 -55 -54 -65 -78 -40 -98 10 -8 22 -4
52 17 77 56 135 142 163 242 7 25 17 55 22 68 12 31 11 178 -1 219 -5 18 -15
50 -21 71 -39 130 -137 247 -251 301 -60 28 -76 31 -155 30 -48 -1 -104 -6
-124 -13z"/>
<path d="M3164 7874 c-133 -91 -186 -252 -131 -400 32 -86 69 -130 101 -118
17 7 22 49 7 58 -5 4 -21 29 -35 58 -50 100 -25 248 51 308 67 53 128 62 198
29 84 -40 129 -125 123 -235 -3 -59 -9 -80 -36 -123 -35 -57 -40 -86 -17 -95
23 -8 37 1 66 42 36 52 53 113 53 191 2 128 -51 226 -155 289 -29 17 -52 22
-111 22 -65 0 -81 -4 -114 -26z"/>
<path d="M21935 10692 c-33 -11 -113 -83 -124 -111 -21 -54 -23 -99 -7 -148
19 -59 70 -123 97 -123 10 0 19 -4 19 -8 0 -9 100 -35 116 -31 41 11 216 -41
304 -90 25 -13 56 -30 70 -37 46 -24 200 -169 200 -189 0 -7 14 -28 30 -46 31
-35 63 -94 75 -139 4 -14 13 -39 21 -57 21 -46 32 -115 39 -243 8 -149 8 -150
53 -207 32 -39 49 -52 96 -66 48 -15 65 -17 110 -7 91 19 133 62 166 172 20
67 -27 421 -69 513 -5 11 -21 47 -36 80 -15 33 -31 67 -36 75 -5 8 -16 29 -24
46 -8 18 -25 45 -38 60 -13 16 -27 38 -31 49 -3 11 -46 61 -95 110 -113 114
-146 143 -201 179 -25 16 -61 40 -81 53 -19 13 -40 23 -46 23 -6 0 -21 8 -34
18 -13 11 -37 24 -54 30 -16 6 -55 20 -85 32 -124 47 -375 83 -435 62z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
